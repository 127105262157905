import React from "react"
import { Div, Text } from "atomize"

import PortableText from "../common/PortableText"
// import ImageLazyLoad from "../common/molecules/ImageLazyLoad"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

export default function FeaturesBlock({ title, body, icon }) {
  return (
    <Div d="flex">
      <Div style={{ minWidth: "80px", height: "80px" }} m={{ r: "1.25rem" }}>
        {/* <Image src={icon.asset.url} h="100%" w="100%" /> */}
        {/* <ImageLazyLoad
          bgImg={icon.asset.url}
          bgSize="contain"
          bgPos="center"
          p={{ b: "100%" }}
          threshold={500}
          transition
        /> */}
        <LazyLoadImage src={icon.asset.url} width="100%" effect="blur" alt="" />
      </Div>
      <Div textAlign="left">
        <Text
          textWeight="bold"
          textSize="header"
          style={{ lineHeight: "16px" }}
        >
          {title}
        </Text>
        <Text m={{ t: "0.625rem" }}>
          <PortableText blocks={body} />
        </Text>
      </Div>
    </Div>
  )
}
